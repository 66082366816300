import React, { useState } from "react";
import Input from "../Input/input";
import { fetchProductsBySearch } from "../../Utils/fetching";
import "./style.scss";
import SearchItem from "../SearchItem/searchItem";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Search = ({ isSearch, setIsSearch }) => {
  const [t, i18n] = useTranslation("global");

  const [products, setProducts] = useState([]);
  const handleSearch = async (e) => {
    const { value } = e.target;
    const result = await fetchProductsBySearch(value.toLowerCase());
    setProducts(result);
  };
  return (
    <div className={`search ${isSearch ? "search--show" : ""}`}>
      <div className="search__wrapper">
        <Input
          onChange={(e) => {
            handleSearch(e);
          }}
          custom_class="search__wrapper__input"
          placeholder={t("search")}
        />
        {products?.length
          ? products?.map((item) => (
              <Link
                onClick={() => {
                  setIsSearch(false);
                }}
                to={`/product/${item.slug.current}`}
                key={item._id}
              >
                <SearchItem product={item} />
              </Link>
            ))
          : null}
      </div>
    </div>
  );
};

export default Search;
