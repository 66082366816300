import React, { useContext } from "react";
import "./style.scss";
import { BsArrowLeft } from "react-icons/bs";
import { CiShoppingCart } from "react-icons/ci";
import { CartContext } from "../../Context/cartContext";
import CartCard from "../CartCard/cartCard";
import Button from "../Button/button";
import { handlePrice } from "../../Utils/other";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Cart = ({ isCart, setIsCart }) => {
  const { cart: cartData, exchangeRate, currency } = useContext(CartContext);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  return (
    <div className={`cart ${isCart ? "cart--show" : ""}`}>
      <div className={`cart__wrapper ${!cartData.length && "empty"}`}>
        {!cartData.length ? (
          <div className="cart__wrapper__empty">
            <CiShoppingCart size={50} className="" />

            <p className="cart__wrapper__empty__label">{t("emptyCart")}</p>
          </div>
        ) : (
          <div className="cart__wrapper__body">
            <div
              className={`cart__wrapper__body__bottom ${
                isCart ? "cart__wrapper__body__bottom--open" : ""
              }`}
            >
              <Button
                label={t("cartButton")}
                className="cart__wrapper__body__bottom__btn"
                handleClick={() => {
                  setIsCart(false);
                  navigate("/billing");
                }}
              />
              <div className="cart__wrapper__body__bottom__right">
                <p className="cart__wrapper__body__bottom__right__price">
                  {Number(handlePrice(cartData) * exchangeRate)
                    .toFixed()
                    .toLocaleString("en", {
                      useGrouping: true,
                    })}
                  {` ${currency}`}
                </p>
                <p className="cart__wrapper__body__bottom__right__vat">
                  * including vat
                </p>
              </div>
            </div>
            <p className="cart__wrapper__body__title">{`CART (${cartData.length})`}</p>
            <div className="cart__wrapper__body__products">
              {cartData.map((product) => (
                <CartCard
                  product={product}
                  key={`${product._id}-${JSON.stringify(product.attributes)}`}
                />
              ))}
            </div>
          </div>
        )}
        <BsArrowLeft
          fontSize={19}
          className="cart__wrapper__icon"
          onClick={() => {
            setIsCart(false);
          }}
        />
      </div>
    </div>
  );
};

export default Cart;
// BsArrowLeft
// GoArrowLeft
