import React, { useEffect, useState } from "react";
import "./style.scss";
import StackedCard from "../../Components/StackedCard/stackedCard";
import Footer from "../../Components/Footer/footer";
import DrawJsx from "../../Components/DrawJsx";
import { fetchHeroImages } from "../../Utils/fetching";
import { urlFor } from "../../config/config";
import DrawJsxSkeleton from "../../Components/SkeletonDrawJSX";

const Home = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [heroImages, setHeroImages] = useState([])

  useEffect(() => {
    fetchHeroImages().then((data) => {
      if (data.length > 0) {
        const myHeros = data[0]
        setHeroImages(myHeros.heroes)
      }

      setIsLoading(false)
    }).catch(e => {
    })

  }, [])


  // fetchHeroImages
  return (
    <div className="home">
      <div className="home__wrapper">
        {isLoading && (
          <DrawJsxSkeleton />
        )}

        {heroImages.length > 0 && !isLoading &&
          heroImages.map(item => (
            <StackedCard
              Comp={
                item.title ? (
                  <DrawJsx
                    href={`/collection/${item?.category?.slug?.current}`}
                    desc={item.subtitle}
                    title={item?.category.name}
                  />
                ) : (
                  <></>
                )
              }
              href={!item.title ? `/collection/${item?.category?.slug?.current}` : ""}
              img={urlFor(item.image)}
            />
          ))

        }
        {/*
         <StackedCard
          Comp={
            <DrawJsx
              href="/collection/retail-bodysuits"
              desc="Soft and cozy essentials for your baby's comfort"
              title="Body Suits"
            />
          }
          img="/images/3.jpeg"
        />
        <StackedCard href="/collection/retail-special-prices" img="/images/4.jpeg" />
        <StackedCard
          Comp={
            <DrawJsx
              href="/collection/retail-babysuits"
              desc=" A collection of stylish and comfortable babysuits for your little fashionista"
              title="Baby Suits"
            />
          }
          href="/collection/retail-stater-sets"
          img="/images/2.jpeg"
        />
        <StackedCard
          Comp={
            <DrawJsx
              href="/collection/retail-stater-sets"
              desc="Thoughtfully curated starter sets for your baby's happy beginning."
              title="Starter Sets"
            />
          }
          href="/collection/PJS"
          img="/images/1.jpeg"
        /> */}

        {/* <StackedCard Comp={<Footer />} /> */}
      </div>
    </div>
  );
};

export default Home;
