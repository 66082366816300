import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import TableComponent from "../../Components/Table/table";
import Cookies from "js-cookie";
import { CartContext } from "../../Context/cartContext";
import { fetchAllOrdersByEmail } from "../../Utils/fetching";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/button";

const Account = () => {
  const [details, setDetails] = useState({});
  const [orders, setOrders] = useState({});
  const { isLoggedin, setIsLoggedin } = useContext(CartContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedin) {
      navigate("/login");
    }
    if (isLoggedin) {
      setDetails(JSON.parse(Cookies.get("userData")));
    }
  }, []);
  const showKeys = [
    "firstName",
    "address",
    "city",
    "country",
    "email",
    "number",
  ];

  useEffect(() => {
    if (isLoggedin) {
      const cookieInfo = JSON.parse(Cookies.get("userData"));
      if (cookieInfo.email) {
        fetchAllOrdersByEmail(cookieInfo.email).then((res) => {
          setOrders(res);
        });
      }
    }
  }, [isLoggedin]);

  if (!isLoggedin) {
    return null;
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <h1 className="account__wrapper__heading">My Account</h1>
        <div className="account__wrapper__history">
          <h3 className="account__wrapper__history__heading">Orders History</h3>
          <div className="account__wrapper__history__table">
            <TableComponent data={orders} />
          </div>
        </div>
        <div className="account__wrapper__details">
          <h3 className="account__wrapper__history__heading">
            Account Details
          </h3>
          <div className="account__wrapper__details__info">
            {details &&
              Object.keys(details).map((key) => {
                if (showKeys.includes(key)) {
                  return (
                    <p
                      key={key}
                      className="account__wrapper__details__info__item"
                    >
                      {details[key]}
                    </p>
                  );
                }
                return null;
              })}
          </div>
        </div>
        <Button
          label=" Logout"
          handleClick={() => {
            navigate("/");
            Cookies.remove("userData");
            setIsLoggedin(false);
          }}
          className="account__wrapper__logout"
        />
      </div>
    </div>
  );
};

export default Account;
