import React, { useContext, useState } from "react";
import { CiMenuBurger, CiSearch, CiShoppingCart } from "react-icons/ci";
import "./style.scss";
import { TfiClose } from "react-icons/tfi";
import { useLocation } from "react-router-dom";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { COUNTRIES_TO_SHIP } from "../../data/data";
import { CartContext } from "../../Context/cartContext";

const Header = ({
  isSearch,
  setIsSearch,
  isSideBar,
  setIsSiderBar,
  setIsCart,
  isCart,
  pages,
  isFilterOpened,
  setIsFilterOpened,
}) => {
  const route = useLocation();
  const { setCurrency } = useContext(CartContext);

  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("country") || "LB")
  const [isCountryListOpened, setIsCountryListOpened] = useState(false)

  const handleIconsColor = () => {
    if (route.pathname === "/" && !(isSearch || isSideBar || isCart)) {
      return "header--icon";
    }
  };

  const handleCountryCurrency = (countryObject) => {
    let selectedCurrency;
    if (countryObject.name == "United Kingdom") {
      selectedCurrency = 'gbp'
    }
    else if (countryObject.name === "Europe") {
      selectedCurrency = 'eur';
    }
    else {
      selectedCurrency = 'usd'
    }
    setCurrency(selectedCurrency.toLowerCase());
    localStorage.setItem("isLanguagePopup", selectedCurrency.toLowerCase());

  }
  // defaultValue={
  //   localStorage.getItem("isLanguagePopup") || "usd"
  // }

  // }

  return (
    <div
      className={`header ${pages.includes(route.pathname) ? "header--v2" : ""}
      
      ${pages.some((i) => route.pathname.includes(i)) ? "header--v2" : ""}
      `}
    >
      <div className="header__wrapper">
        <div className="header__wrapper__left">
          {isSideBar || isSearch ? (
            <TfiClose
              className={`header__wrapper__left__icon icon ${handleIconsColor()}`}
              onClick={() => {
                // if (isSearch) {
                setIsSearch(false);
                // } else {
                setIsSiderBar(false);
                // }
              }}
            />
          ) : (
            <CiMenuBurger
              onClick={() => {
                setIsSiderBar(true);
              }}
              className={`header__wrapper__left__icon icon ${handleIconsColor()}`}
            />
          )}
          <p
            className={`header__wrapper__filter ${!route.pathname.includes("/collection/") ? "d-none" : ""
              }`}
            onClick={() => {
              setIsFilterOpened(true);
            }}
          >
            Filters
          </p>
          <img
            // src="https://static.zara.net/photos///contents/cm/assets/logos/default-light-yellow_0.svg"
            src="/images/logo.jpeg"
            alt="my Logo"
            className={`header__wrapper__left__logo ${(isSearch || isSideBar) && "header__wrapper__left__logo--hide"
              }
            ${pages.some((page) => route.pathname.includes(page))
                ? "header__wrapper__left__logo--hide"
                : ""
              }
            `}
          />
        </div>
        <div className="header__wrapper__right">
          {/* {route.pathname.includes("product") ? (
            <CiExport
              onClick={handleShare}
              size={20}
              className="header__wrapper__right__icon icon share"
            />
          ) : null} */}

          <div className="header__wrapper__right__country">
            <div
              onClick={() => {
                setIsCountryListOpened(true)
              }}
              className="header__wrapper__right__country__selected">
              {getUnicodeFlagIcon(selectedCountry)}
            </div>


            {isCountryListOpened && (
              <div className="header__wrapper__right__country__list">
                {COUNTRIES_TO_SHIP.map(cntry => {
                  if (cntry.type !== "eu") {
                    return (
                      <div
                        key={cntry.name}
                        onClick={() => {
                          setSelectedCountry(cntry.code)
                          setIsCountryListOpened(false)
                          localStorage.setItem("country", cntry.code)
                          handleCountryCurrency(cntry)

                        }}
                        className="header__wrapper__right__country__list__item">
                        <div className="">
                          {getUnicodeFlagIcon(cntry.code)}
                        </div>
                        <div className="">{cntry.name}</div>
                      </div>
                    )
                  }
                }

                )

                }
                <div
                  key="eu"
                  onClick={() => {
                    setSelectedCountry("eu")
                    setIsCountryListOpened(false)
                    localStorage.setItem("country", "eu")
                    handleCountryCurrency(
                      { name: 'Europe', code: 'GB', type: "eu" },
                    )
                  }}
                  className="header__wrapper__right__country__list__item">
                  <div className="">
                    {getUnicodeFlagIcon("eu")}
                  </div>
                  <div className="">Europe</div>
                </div>
              </div>
            )}


          </div>



          <CiSearch
            onClick={() => {
              setIsSearch((prev) => !prev);
            }}
            size={21}
            className={`header__wrapper__right__icon icon ${handleIconsColor()}`}
          />

          {/* 
 
 
 */}




          <CiShoppingCart
            onClick={() => {
              setIsCart(true);
            }}
            size={21}
            className={`header__wrapper__right__icon icon ${handleIconsColor()}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
