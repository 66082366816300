import { TfiPlus, TfiMinus } from "react-icons/tfi";
import React, { useContext } from "react";

import "./style.scss";
import { CartContext } from "../../Context/cartContext";
const QuantityCounter = ({
  quantity,
  SetQuantity = () => {},
  inCart = false,
  productId = 0,
  product,
}) => {
  const { handleQuantity } = useContext(CartContext);
  return (
    <div className="quantityCounter">
      <div className="quantityCounter__wrapper">
        <div
          className="quantityCounter__wrapper__left"
          onClick={() => {
            if (!inCart) {
              quantity !== 1 && SetQuantity((prev) => prev - 1);
            } else {
              handleQuantity(product, "-");
            }
          }}
        >
          <TfiMinus className="quantityCounter__wrapper__right__icon" />
        </div>
        <div className="quantityCounter__wrapper__count">{quantity}</div>
        <div
          onClick={() => {
            if (!inCart) {
              SetQuantity((prev) => prev + 1);
            } else {
              handleQuantity(product, "+");
            }
          }}
          className="quantityCounter__wrapper__right"
        >
          <TfiPlus className="quantityCounter__wrapper__left__icon" />
        </div>
      </div>
    </div>
  );
};

export default QuantityCounter;
