import React, { useContext } from "react";
import "./style.scss";
import { urlFor } from "../../config/config";
import { calculateSalePercentage, checkIfPrice } from "../../Utils/other";
import { CartContext } from "../../Context/cartContext";
import { RATES } from "../../data/data";
const Card = ({ product }) => {

  const { exchangeRate, currency } = useContext(CartContext);
  return (
    <div forbottomsheet="true" className="card">
      <div forbottomsheet="true" className="card__wrapper">
        <div className="card__wrapper__media">
          <img
            src={
              product.mainImage
                ? urlFor(product.mainImage)
                : "https://via.placeholder.com/200/ddd/ddd"
            }
            className="card__wrapper__media__image"
            alt=""
            loading="lazy"
          />
        </div>
        <div forbottomsheet="true" className="card__wrapper__lower">
          <p forbottomsheet="true" className="card__wrapper__lower__name">
            {product.name}
          </p>
          <p
            forbottomsheet="true"
            className={`card__wrapper__lower__price ${product.sale ? "sale" : ""
              }`}
          >
            {checkIfPrice(product, RATES[currency.toLowerCase()], currency)}
          </p>
          {product.sale ? (
            <p className="card__wrapper__lower__sale">
              {
                `
               ${(product.sale * exchangeRate).toFixed()} ${currency}`}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Card;
