import DataTable from "react-data-table-component";
const columns = [
  {
    name: "orderID",
    selector: (row) => row.orderID,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
  {
    name: "Cost",
    selector: (row) => row.cost,
  },
  {
    name: "orderDate",
    selector: (row) => row.orderDate,
  },
];

export default function MyComponent({ data }) {
  return <DataTable columns={columns} data={data} />;
}
