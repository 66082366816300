import React from "react";
import "./style.scss";
const Attributes = ({ data = [], isColor, size, color, setColor, setSize }) => {
  return (
    <div className="attributes">
      {isColor && data?.length ? (
        <div className="attributes__wrapper">
          {data?.map((col) => (
            <div
              key={col}
              style={{ background: col }}
              onClick={(e) => {
                setColor(col);
              }}
              className={`attributes__wrapper__color ${
                color === col ? "active" : ""
              }`}
            />
          ))}
        </div>
      ) : (
        <div className="attributes__wrapper">
          {data?.length
            ? data?.map((s) => (
                <div
                  key={s}
                  onClick={(e) => {
                    setSize(s);
                  }}
                  className={`attributes__wrapper__size ${
                    size === s ? "active" : ""
                  }`}
                >
                  {s}
                </div>
              ))
            : ""}
        </div>
      )}
    </div>
  );
};

export default Attributes;
