import React, { useContext, useEffect, useState } from "react";
import { RATES } from "../../data/data";
import { fetchCategories, fetchFirstCategories } from "../../Utils/fetching";
import "./style.scss";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/cartContext";
import { useTranslation } from "react-i18next";

const SideBar = ({ isSideBar, setIsSiderBar }) => {
  const { currency, setCurrency, setExchangeRate, isLoggedin } = useContext(CartContext);
  const [shownCategory, setShownCategory] = useState("retail");
  const [t] = useTranslation("global");
  const [fetchedCategories, setFetchedCategories] = useState({
    retail: [],
  })

  useEffect(() => {
    const fetchExchangeRate = async () => {
      switch (currency.toUpperCase()) {
        case "USD":
          setExchangeRate(RATES.usd);
          break;
        case "EUR":
          setExchangeRate(RATES.eur);
          break;
        case "GBP":
          setExchangeRate(RATES.gbp);
          break;
        case "BRL":
          setExchangeRate(RATES.brl);
          break;
        case "CFA":
          setExchangeRate(RATES.oxf);
          break;
        case "AED":
          setExchangeRate(RATES.aed);
          break;
        default:
          setExchangeRate(10);
      }
      // if (currency !== "CFA") {
      //   try {
      //     const response = await axios.get(
      //       `https://openexchangerates.org/api/latest.json?app_id=983cccd01e7a489daed770cea4a18fa5&base=USD&symbols=${currency}`
      //     );
      //     const fetchedExchangeRate = response.data.rates[currency];
      //     setExchangeRate(fetchedExchangeRate);
      //   } catch (error) {
      //     console.error("Error fetching exchange rate:", error);
      //   }
      // } else {
      //   try {
      //     const response = await axios.get(
      //       `https://openexchangerates.org/api/latest.json?app_id=983cccd01e7a489daed770cea4a18fa5&base=USD&symbols=XOF`
      //     );
      //     const fetchedExchangeRate = response.data.rates["XOF"];
      //     setExchangeRate(fetchedExchangeRate);
      //   } catch (error) {
      //     console.error("Error fetching exchange rate:", error);
      //   }
      // }
    };

    // if () {
    fetchExchangeRate();
    // }
    // eslint-disable-next-line
  }, [currency]);


  useEffect(() => {

    fetchCategories().then(async data => {
      const demoCategories = {
        retail: [],
      }
      let firstCats = []

      const getFirstCatsData = await fetchFirstCategories()
      //["///  NEW ", 'Rompers & Overalls', 'Matching Sets', 'Starter Sets', 'basics', 'Pyjamas', 'Dresses', 'Bottoms/Tights', 'Puffy Collection', 'Knit wear', 'Accessories', 'Special Prices', 'Children']


      if (getFirstCatsData.length > 0 && getFirstCatsData[0].categories) {
        getFirstCatsData[0].categories.forEach(item => {
          firstCats.push(item.name)
        })
      }

      let firstCatsV2 = []
      let restCatsV2 = []

      if (data.length > 0) {



        firstCats.forEach(cat => {
          data.forEach(catObj => {
            if (cat === catObj.name && catObj.type == "retail") {
              firstCatsV2.push(catObj)
              return
            }
          })
        })

        data.forEach(catObj => {
          if (!firstCats.includes(catObj.name) && catObj.type == "retail") {
            restCatsV2.push(catObj)
            return
          }
        })


        const retail = []
        setFetchedCategories({
          retail: [...firstCatsV2, ...restCatsV2]
        })
      }
    })

  }, [])

  return (
    <div className={`sideBar ${isSideBar ? "sideBar--show" : ""}`}>
      <div className="sideBar__wrapper">
        <div className="sideBar__wrapper__sections">
          {["retail"].map((cat) => (
            <div
              key={cat}
              onClick={() => {
                setShownCategory(cat);
              }}
              className={`sideBar__wrapper__sections__label ${shownCategory === cat ? "active" : ""
                }`}
            >
              {cat}
            </div>
          ))}
        </div>
        <div className="sideBar__wrapper__categories">
          {["retail"].map((cat) =>
            fetchedCategories[cat].map((item, index) => {
              if (cat === shownCategory) {
                return (
                  <div


                    key={item.slug.current}
                    className={`sideBar__wrapper__categories__item 
                    ${shownCategory === cat ? "animate" : ""
                      }`}
                  >
                    <Link
                      style={item?.name.toLowerCase() == "special prices" ? {
                        backgroundColor: "#ffe800", padding: "4px 8px", borderRadius: "4px"
                      } : {}}
                      onClick={() => {
                        setIsSiderBar(false);
                      }}
                      to={`/collection/${item.slug.current}`}
                    >
                      {item?.name}
                    </Link>
                  </div>
                );
              } else {
                return null;
              }
            })
          )}
        </div>
        <div className="sideBar__wrapper__bottom">
          {/* <select
            name="currency"
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
            defaultValue={currency}
            className="sideBar__wrapper__bottom__dropdown"
          >
            {CURRENCIES_DATA.map((cur) => (
              <option key={cur.id} className="">
                {cur.currency}
              </option>
            ))}
          </select> */}
          {/* <select
            name="currency"
            onChange={(e) => {
              const selectedCurrency = e.target.value.toLowerCase();
              const country = COUNTRIES.find(
                (cur) => cur.name.toLowerCase() === selectedCurrency
              );
              setCurrency(country.currency);
              changeLanguage(country.language);
              // setIsChooseLanguageOpen(false);
              localStorage.setItem("isLanguagePopup", JSON.stringify(country));
            }}
            defaultValue={
              JSON.parse(localStorage.getItem("isLanguagePopup"))?.name ||
              currency
            }
            className="sideBar__wrapper__bottom__dropdown"
          >
            {COUNTRIES.map((cur) => (
              <option key={cur.id} className="">
                {cur.name}
              </option>
            ))}
          </select> */}


          {/* <select
            name="currency"
            onChange={(e) => {
              const selectedCurrency = e.target.value.toLowerCase();
              console.log(selectedCurrency);
              setCurrency(selectedCurrency.toLowerCase());
              localStorage.setItem("isLanguagePopup", selectedCurrency.toLowerCase());

            }}
            defaultValue={
              localStorage.getItem("isLanguagePopup") || "usd"
            }
            className="sideBar__wrapper__bottom__dropdown"
          >

            {Object.keys(RATES).map(cur => (
              <option key={cur} className="">
                {cur}
              </option>
            ))}

          </select> */}




          <Link
            onClick={() => {
              setIsSiderBar(false);
            }}
            className="sideBar__wrapper__bottom__item"
            to="/"
          >
            Home
          </Link>
          <Link
            onClick={() => {
              setIsSiderBar(false);
            }}
            className="sideBar__wrapper__bottom__item"
            to={isLoggedin ? "/account" : "/login"}
          >
            {isLoggedin ? "Account" : "Login"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
