import emailjs from "emailjs-com";
import CryptoJS from 'crypto-js';
import axios from 'axios';

export const calculateSalePercentage = (originalPrice, salePrice) => {
  if (originalPrice <= 0 || salePrice <= 0) {
    return 0;
  }

  const percentage = ((originalPrice - salePrice) / originalPrice) * 100;
  return Math.round(percentage);
};
export const handleShare = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: "Title of the shared content",
        text: "Description of the shared content",
        url: "https://google.com/",
      });
      console.log("Shared successfully");
    } catch (error) {
      console.error("Error sharing:", error);
    }
  } else {
    alert("not supported");
    console.log("Web Share API not supported");
    // Fallback behavior for unsupported browsers
  }
};
export const handlePrice = (cartData) => {
  let total = 0;
  cartData.forEach((item) => {
    if (item.sale > 0) {
      total += item.sale * item.quantity;
    } else {
      total += item.price * item.quantity;
    }
  });
  return total;
};

export const emailProductsHandle = (cart) => {
  let products = ``;
  cart.forEach((item) => {
    products += `
      Product Name: ${item.name} x ${item.quantity} ${item?.attributes?.size || ""
      } ${item?.attributes?.color || ""}
      `;
  });
  return products;
};

export const handleSendEmail = (
  formData,
  total = { total: 0, currency: "USD" },
  cart
) => {
  let msg = `Customer Email: ${formData.email}
    Customer Name: ${formData.name} ${formData.surname}
    Address: ${formData.country} / ${formData.city} / ${formData.address}
    email: ${formData.email}
    shipping: ${formData.shipping.description} ${formData.shipping.price} ${total.currency
    }
    phone: ${formData.number}
    Products:
    ${emailProductsHandle(cart)}
    total: ${total.total} ${total.currency}
    Payment: ${formData.payment}
    `;
  console.log(msg);
  sendEmail(msg, formData.name);
};
export const sendEmail = (msg, name) => {
  const data = {
    to_name: "admin",
    from_name: `${name}`,
    message: msg,
    sendTo: "malek0x1@gmail.com",
  };

  emailjs
    .send("service_5zw66kn", "template_zum5x5w", data, "YNsBoJz3XqtfyO-50")
    .then(
      function (response) {
        console.log("SUCCESS!");
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
};
export const handleOverViewPrice = (cart, exchangeRate, shippingCost) => {
  return Number(handlePrice(cart) * exchangeRate + Number(shippingCost))
    .toFixed()
    .toLocaleString("en", { useGrouping: true });
};
export const randomIdGenerator = () => {
  return Math.random().toString(36).substring(2, 100).toUpperCase();
};
export const getDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
export const checkIfPrice = (product, exchangeRate, currency) => {
  if (product.price) {
    return (Number(product.price) * Number(exchangeRate)).toFixed() + ` ${currency}`
  } else {
    return "Contact for price"
  }
}

export const checkIfPriceOnlyNumberQuantity = (product, exchangeRate, currency, quantity) => {
  if (product.price) {
    return (Number(product.price) * Number(exchangeRate)).toFixed()
  } else {
    return 0
  }
}

export const checkIfPriceOnlyNumberQuantitySale = (product, exchangeRate, currency, quantity) => {
  if (product.sale) {
    return (Number(product.sale) * Number(exchangeRate)).toFixed()
  } else {
    return 0
  }
}


// export function generateHash(order_number, order_amount, order_currency, order_description, merchant_pass) {
//   const to_md5 = order_number + order_amount + order_currency + order_description + merchant_pass;
//   const md5Hash = CryptoJS.MD5(to_md5.toUpperCase()).toString();
//   const sha1Hash = CryptoJS.SHA1(md5Hash).toString(CryptoJS.enc.Hex).toUpperCase();
//   return sha1Hash;
// }

// // Function to make the authentication request
// export function makeAuthenticationRequest() {
//   const order_number = "1411163";
//   const order_amount = "1.00";
//   const order_currency = "USD";
//   const order_description = "Insurance Policy";
//   const merchant_pass = "cfba4cf8280f0cba24ef38b9c9a29fb6";

//   const requestData = {
//     "merchant_key": "6e8e2cc6-df5f-11ed-9d12-7ac7a76eb3bd",
//     "operation": "purchase",
//     "methods": ["card"],
//     "order": {
//       "number": order_number,
//       "amount": order_amount,
//       "currency": order_currency,
//       "description": order_description
//     },
//     "cancel_url": "https://example.com/cancel",
//     "success_url": "https://example.com/success",
//     "customer": {
//       "name": "John Doe",
//       "email": "test@gmail.com"
//     },
//     "hash": generateHash(order_number, order_amount, order_currency, order_description, merchant_pass)
//   };

//   fetch('https://checkout.montypay.com/api/v1/session', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Access-Control-Allow-Origin': 'https://www.solbabybrand.com/',
//       'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
//     },
//     body: JSON.stringify(requestData)
//   })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return response.json();
//     })
//     .then(data => {
//       console.log(data);
//       // window.location.href = data.redirect_url;
//     })
//     .catch(error => {
//       console.error('There was a problem with the fetch operation:', error);
//     });

// }


// Import CryptoJS library

// Function to calculate the hash
function calculateHash() {
  const order_number = "1411163";
  const order_amount = "1.00";
  const order_currency = "USD";
  const order_description = "Insurance Policy";
  const merchant_pass = "cfba4cf8280f0cba24ef38b9c9a29fb6";

  const to_md5 = order_number + order_amount + order_currency + order_description + merchant_pass;
  const hash = CryptoJS.SHA1(CryptoJS.MD5(to_md5.toUpperCase()).toString());
  return CryptoJS.enc.Hex.stringify(hash);
}

// Function to make the API request
export async function makeRequest() {
  const session_hash = calculateHash();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'origin': '93.126.213.31',
      'access-control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    },
    body: JSON.stringify(
      {
        "merchant_key": "6e8e2cc6-df5f-11ed-9d12-7ac7a76eb3bd",
        "operation": "purchase",
        "cancel_url": "https://merchantapp.montypay.com/paycancel",
        "success_url": "https://merchantapp.montypay.com/paysuccess",
        "hash": session_hash,
        "methods": ["card"],
        "order": {
          "description": "Insurance Policy",
          "number": "1411163",
          "amount": "1.00",
          "currency": "USD"
        },
        "billing_address": {
          "phone": "+35796123456",
          "zip": "123566",
          "address": "test",
          "city": "Los Angeles",
          "country": "CY",
          "state": "CA"
        },
        "customer": {
          "name": "Hadi Hassoun",
          "email": "hadi.hassoun@montypay.com"
        }
      }
    )
  };

  try {
    const response = await fetch('https://checkout.montypay.com/api/v1/session', requestOptions);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error:', error);
  }
}

// Call the function to make the request


// export const sendOrderData = async (orderAmount, email, orderCurrency) => {
//   try {
//     const orderData = {
//       orderAmount,
//       email,
//       orderCurrency
//     };

//     const response = await axios.post('https://nodejs-serverless-function-express-blond-ten.vercel.app/api/hello', {
//       orderAmount: "99", email: "as@gmail.com", orderCurrency: "USD"
//     });
//     console.log(response);


//     if (response.data && response.data.redirect_url) {
//       window.location.href = response.data.redirect_url
//     }
//   } catch (error) {
//     // console.error(error);
//   }
// };



export const handleCheckout = async (orderData) => {
  const data = {
    amount: orderData.cost,
    currency: orderData.currency.toUpperCase(),
    orderID: orderData.orderID
  };
  axios.post('https://nodejs-serverless-function-express-2-phi.vercel.app/api/hello', data)
    .then(response => {
      console.log('Response:', response.data);

      if (response.data.redirect_url) {

        window.location.href = response.data.redirect_url
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

export const generateOrderNumber = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = 8;
  let orderNumber = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    orderNumber += characters.charAt(randomIndex);
  }

  return orderNumber;
};

