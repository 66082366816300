import React from "react";
import { HiOutlineCheck } from "react-icons/hi";
import "./style.scss";
import { useTranslation } from "react-i18next";
const OrderPlaced = () => {
  const [t] = useTranslation("global");
  return (
    <div className="orderPlaced">
      <div className="orderPlaced__wrapper">
        <div className="orderPlaced__wrapper__icon">
          <div className="orderPlaced__wrapper__icon--animation"></div>
          <HiOutlineCheck className="orderPlaced__wrapper__icon--icon" />
        </div>
        <h3 className="orderPlaced__wrapper__heading">
          {t("orderPlacedHeading")}
        </h3>
        <div className="orderPlaced__wrapper__description">
          {t("orderPlacedDescription")}
        </div>
      </div>
    </div>
  );
};

export default OrderPlaced;
