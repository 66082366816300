import React from "react";
import "./style.scss";
import Slider from "react-slider";
import { useState } from "react";
const MIN = 10;
const MAX = 1000;

const PriceRange = ({ filterType, setFilterType }) => {
  const [values, setValues] = useState([MIN, MAX]);

  return (
    <div className="priceRange">
      <div className="priceRange__wrapper">
        <p className="priceRange__wrapper__item">{values[0]} USD</p>
        <p className="priceRange__wrapper__item">{values[1]} USD</p>
      </div>
      <Slider
        className="priceRange__slider"
        value={values}
        onAfterChange={(e) => {
          setFilterType({
            type: "price",
            data: {
              min: e[0],
              max: e[1],
            },
          });
        }}
        min={MIN}
        onChange={(e) => {
          setValues(e);
        }}
        max={MAX}
      />
    </div>
  );
};

export default PriceRange;
