import { client } from "../config/config";

export const fetchAllProduct = async (categorySlug = null) => {
  let query;
  if (categorySlug) {
    query = `*[_type == "product" && category->slug.current == $categorySlug]`;
  } else {
    query = '*[_type == "product"]{price,name,category->}';
  }

  const fetchedProducts = await client.fetch(query, { categorySlug });
  return fetchedProducts;
};


export const fetchProductById = async (productId) => {
  const query = '*[_type == "product" && _id == $productId]';
  const params = { productId };
  const fetchedProduct = await client.fetch(query, params);
  return fetchedProduct[0];
};
export const fetchUserInfo = async (email) => {
  const query = '*[_type == "user" && email == $email]';
  const params = { email };
  const fetchedInfo = await client.fetch(query, params);
  return fetchedInfo[0];
};

export const fetchProductBySlug = async (slug) => {
  const query = '*[_type == "product" && slug.current == $slug ]{...,category->}';
  const params = { slug };
  const fetchedProduct = await client.fetch(query, params);
  return fetchedProduct[0];
};





export const removeProductById = async (productId) => {
  const mutation = `*[_type == "product" && _id == $productId]`;
  const params = { productId };
  await client.delete(mutation, params);
};

export const fetchProductsByCategory = async (category, limit = "") => {
  let query = '*[_type == "product" && category->slug.current == $category]';
  const params = { category };

  if (limit) {
    query += ` | order(_createdAt desc) [0...${limit}]`;
  }

  const [fetchedProducts, totalCount] = await Promise.all([
    client.fetch(query, params),
    client.fetch(
      `count(*[_type == "product" && category == $category])`,
      params
    ),
  ]);

  return { products: fetchedProducts, total: totalCount };
};


export const fetchProductsByCategoryV2 = async (schemaName = "product", category, to = 9) => {
  try {
    // Define your query to fetch products within the specified range
    const query = `{
        "items": *[_type == '${schemaName}' && category->slug.current == $category] | order(_createdAt desc) [0...${to}],
        "totalItems": count(*[_type == '${schemaName}' && category->slug.current == $category])
      }`;
    const params = { category };


    // Fetch data from Sanity
    const { items, totalItems } = await client.fetch(query, params);
    return { items, totalItems };
  } catch (error) {
    console.error('Error fetching products from Sanity:', error);
    throw error; // Propagate the error to the caller
  }
};








export const fetchProductsSortedBy = async (sortType) => {
  let query;

  if (sortType === "price") {
    query = '*[_type == "product"] | order(price)';
  } else if (sortType === "name") {
    query = '*[_type == "product"] | order(name)';
  } else {
    query = '*[_type == "product"]';
  }

  const fetchedProducts = await client.fetch(query);
  return fetchedProducts;
};

export const fetchProductsBySearch = async (searchTerm) => {
  if (searchTerm.length > 2) {
    const query = `*[_type == "product" && name match "*${searchTerm}*"]`;
    const fetchedProducts = await client.fetch(query);
    return fetchedProducts;
  }
};
export const fetchProductsByPriceRange = async (
  minPrice,
  maxPrice,
  category
) => {
  const query = `*[_type == "product" && price >= $minPrice && price <= $maxPrice && category->slug.current == $category]]`;
  const params = { minPrice, maxPrice, category };
  const fetchedProducts = await client.fetch(query, params);
  return fetchedProducts;
};

export const fetchProductsBySize = async (size, category) => {
  const query = `*[_type == "product" && $size in sizes[]] && category->slug.current == $category]`;
  const params = { size, category };
  const fetchedProducts = await client.fetch(query, params);
  return fetchedProducts;
};
export const fetchProductsByColor = async (color, category) => {
  const query = `*[_type == "product" && $color in colors[]] && & category->slug.current == $category]`;
  const params = { color, category };
  const fetchedProducts = await client.fetch(query, params);
  return fetchedProducts;
};
export const submitUserData = async (userData) => {
  try {
    const response = await client.create({ _type: "user", ...userData });
    console.log("User data submitted successfully:", response);
    return response;
  } catch (error) {
    console.error("Error submitting user data:", error);
    throw new Error("Failed to submit user data");
  }
};

export const checkUserCredentials = async (email, password) => {
  try {
    const query = `*[_type == "user" && email == $email && password == $password]`;
    const params = { email, password };
    const matchingUsers = await client.fetch(query, params);
    if (matchingUsers.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking user credentials:", error);
    throw new Error("Failed to check user credentials");
  }
};
export const submitOrderData = async (orderData) => {
  try {
    const response = await client.create({ _type: "order", ...orderData });
    return response;
  } catch (error) {
    console.error("Error submitting order data:", error);
    throw new Error("Failed to submit order data");
  }
};
export const fetchAllOrdersByEmail = async (email) => {
  const query = '*[_type == "order" && email == $email]';
  const params = { email };

  const fetchedProducts = await client.fetch(query, params);
  return fetchedProducts;
};

export const fetchCategories = async () => {
  const query = '*[_type == "category"] | order(_updatedAt desc)';
  const fetchedProducts = await client.fetch(query);
  return fetchedProducts;
};
export const fetchFirstCategories = async () => {
  const query = '*[_type == "CategoriOrder"]{categories[]->}';

  const fetchedProducts = await client.fetch(query);
  console.log(fetchedProducts);
  return fetchedProducts;
};


export const fetchProductsByCategory2 = async (category, limit = "") => {
  let query = '*[_type == "product" && category._ref == $category]'; // Use category._ref to match the reference
  const params = { category: category._id }; // Use category._id as the parameter value

  if (limit) {
    query += ` | order(_createdAt desc) [0...${limit}]`;
  }

  const products = await client.fetch(query, params);
  return products;
}


export const fetchProductsByCategory3 = async (categoryName, limit = "") => {
  let query = '*[_type == "product" && category->name == $categoryName]'; // Use category->name to access the name field of the referenced category
  const params = { categoryName };

  if (limit) {
    query += ` | order(_createdAt desc) [0...${limit}]`;
  }

  const products = await client.fetch(query, params);
  return products;
}


export const fetchProductsByCategory4 = async (categoryName, limit = "") => {
  // Query to fetch the category document based on the name
  const categoryQuery = '*[_type == "category" && name == $categoryName][0]._id';
  const categoryParams = { categoryName };

  // Fetch the category ID
  const categoryId = await client.fetch(categoryQuery, categoryParams);

  // Query products based on the category reference
  let productQuery = '*[_type == "product" && category._ref == $categoryId]';
  const productParams = { categoryId };

  if (limit) {
    productQuery += ` | order(_createdAt desc) [0...${limit}]`;
  }

  // Fetch products based on the category reference
  const products = await client.fetch(productQuery, productParams);
  return products;
}

// export const fetchHeroImages = async () => {
//   const query = '*[_type == "hero"] ';

//   const fetchedProducts = await client.fetch(query);
//   return fetchedProducts;
// };
export const fetchHeroImages = async () => {
  const query = '*[_type == "hero"]{heroes[]{image, title, subtitle, "category": category->}}';

  const fetchedProducts = await client.fetch(query);
  return fetchedProducts;
};


export const getOrderByOrderID = async (orderID) => {
  try {
    // Query for the order document with the matching orderID
    const query = `*[_type == "order" && orderID == $orderID][0]`;
    const params = { orderID };
    const response = await client.fetch(query, params);

    return response;
  } catch (error) {
    console.error('Error retrieving order by orderID:', error);
    throw new Error('Failed to retrieve order by orderID');
  }
};