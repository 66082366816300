import React, { useEffect, useState } from "react";
import "./style.scss";
import Card from "../../Components/Card/card";
import Spinner from "../../Components/Spinner/spinner";
import Button from "../../Components/Button/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchProductsByCategory,
  fetchProductsByCategoryV2,
  fetchProductsByColor,
  fetchProductsByPriceRange,
  fetchProductsBySize
} from "../../Utils/fetching";
import SkeletonCard from "../../Components/SkeletonCard/skeletonCard";

const Collection = ({ filterType, setFilterType, setIsFilterOpened }) => {
  const [productsToShow, setProductsToShow] = useState(8); // Number of products to show initially
  const productsStep = 10; // Step size for each "show more" click
  const navigate = useNavigate();
  const { category } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isViewMoreLoading, SetIsViewMoreLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState([]);
  const [isNoProducts, setIsNoProducts] = useState(false);



  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const fetchedProducts = await fetchProductsByCategoryV2(
        "product",
        category,
        productsToShow
      );
      setIsLoading(false);
      if (fetchedProducts.items.length > 0) {
        setProducts(fetchedProducts.items);
        setTotal(fetchedProducts.totalItems);
        setIsNoProducts(false);
      }
      if (fetchedProducts.items.length < 1) {
        setIsNoProducts(true);
      }
    } catch (e) {
      navigate("/");
    }
  };





  useEffect(() => {
    SetIsViewMoreLoading(true);
    fetchProducts().then((e) => {
      SetIsViewMoreLoading(false);
    });
    // eslint-disable-next-line
  }, [productsToShow]);

  useEffect(() => {
    fetchProducts();
    setProductsToShow(8);
    window.scrollTo(0, 0);
  }, [category]);

  const handleFilterOption = async () => {
    if (!filterType.type) {
      return; // No filter type specified, exit the function
    }

    setIsLoading(true);

    try {
      let filteredProducts = [];

      // switch (filterType.type) {
      //   case "price":
      //     filteredProducts = await fetchProductsByPriceRange(
      //       filterType.data.min,
      //       filterType.data.max,
      //       category
      //     );
      //     break;
      //   case "color":
      //     filteredProducts = await fetchProductsByColor(
      //       filterType.data,
      //       category
      //     );
      //     break;
      //   case "size":
      //     filteredProducts = await fetchProductsBySize(
      //       filterType.data,
      //       category
      //     );
      //     break;
      //   default:
      //     break;
      // }

      if (filteredProducts.length) {
        setProducts(filteredProducts);
      }
    } catch (error) {
      console.error("Error occurred while filtering products:", error);
    } finally {
      setIsLoading(false);
      setIsFilterOpened(false);
    }
  };

  useEffect(() => {
    handleFilterOption(filterType);
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [filterType]);

  if (isNoProducts) {
    return (
      <div className="collection">
        <div className="collection__wrapper">
          <p style={{ textAlign: "center", width: "100%", paddingTop: 30 }}>
            No Products Found !
          </p>
        </div>
      </div>
    );
  }


  return (
    <div className="collection">
      <div className="collection__wrapper">
        {!isLoading
          ? products?.map((item) => (
            <div key={item._id} className="collection__wrapper__item">
              <Link to={`/product/${item.slug.current}`}>
                <Card product={item} />
              </Link>
            </div>
          ))
          : Array.from({ length: 9 }).map((_, index) => (
            <div key={index} className="collection__wrapper__item">
              <SkeletonCard />
            </div>
          ))}
      </div>

      <div className="collection__wrapper__viewMore">
        {products.length < total && (
          <Button
            size="sm"
            className="collection__wrapper__viewMore__btn"
            handleClick={() => {
              setProductsToShow(productsToShow + productsStep);
            }}
            label="Show More"
          />
        )}

        {isViewMoreLoading && <Spinner />}
      </div>
    </div>
  );
};

export default Collection;
