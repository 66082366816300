import React from "react";
import "./style.scss";

const RadioInput = ({ name, description, price, id, setFormData, keyName }) => {
  // const id = generateRandomId2();
  const handleCheckboxChange = (event) => {
    document.querySelectorAll(`.radioInput__wrapper.${name}`).forEach((i) => {
      i.classList.remove("checked");
    });
    if (event.target.checked) {
      document
        .querySelector(`.radioInput__wrapper[radio-id="${id}"]`)
        .classList.add("checked");
    }
  };
  return (
    <div className="radioInput">
      <label>
        <div className={`radioInput__wrapper ${name}`} radio-id={id}>
          <div className="radioInput__wrapper__left">
            <input
              required
              type="radio"
              className="radioInput__wrapper__left__checkbox"
              name={name}
              onChange={(event) => {
                handleCheckboxChange(event);
                if (setFormData) {
                  if (name === "shipping") {
                    // for shipping need to include price
                    setFormData((prev) => {
                      return {
                        ...prev,
                        [keyName]: { description: description, price: price }, // for shipping
                      };
                    });
                  } else {
                    setFormData((prev) => {
                      return { ...prev, [keyName]: description };
                    });
                  }
                }
              }}
            />
            <p className="radioInput__wrapper__left__description">
              {description}
            </p>
          </div>
          {price && (
            <div className="radioInput__wrapper__right">
              <p className="radioInput__wrapper__right__price">{price}$</p>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default RadioInput;
