import React, { useContext, useEffect } from "react";
import Input from "../Input/input";
import "./style.scss";
import { BsArrowLeft } from "react-icons/bs";
import { CartContext } from "../../Context/cartContext";

import Button from "../Button/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { COUNTRIES_TO_SHIP } from "../../data/data";
const AddressForm = ({ formData, setFormData }) => {

  const excludedKeys = ["ratio", "shipping", "payment"];
  const navigate = useNavigate();
  const { isLoggedin, currency } = useContext(CartContext);

  const showKeys = [
    "name",
    "surname",
    "email",
    "address",
    "number",
    "city",
    // "country",
  ];
  const [t] = useTranslation("global");
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/shipping");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(formData);
    if (isLoggedin) {
      const userInfo = JSON.parse(Cookies.get("userData"));
      setFormData((prev) => {
        return {
          name: prev.name ? prev.name : userInfo.firstName,
          surname: prev.surname ? prev.surname : userInfo.lastName,
          email: prev.email ? prev.email : userInfo.email,
          address: prev.address ? prev.address : userInfo.address,
          number: prev.number ? prev.number : userInfo.number,
          city: prev.city ? prev.city : userInfo.city,
          country: userInfo.country,
          ratio: "",
        };
      });
    }
  }, [isLoggedin]);
  return (
    <div className="AddressForm">
      <div className="AddressForm__wrapper">
        <BsArrowLeft
          fontSize={19}
          className="AddressForm__wrapper__icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="AddressForm__wrapper__heading">{t("formHeading")}</p>
        <p className="AddressForm__wrapper__subheading">
          {t("formDescription")}
        </p>
        <form
          onSubmit={handleSubmit}
          action=""
          className="AddressForm__wrapper__form"
        >
          {Object.keys(formData).map((key, index) => {
            if (!excludedKeys.includes(key) && showKeys.includes(key)) {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Input
                    id={key}
                    name={key}
                    type="text"
                    value={formData[key]}
                    autoComplete="on"
                    key={index}
                    placeholder={
                      t("placeholders", { returnObjects: true })[key]
                    }
                    required
                    onChange={(e) => {
                      setFormData((prev) => {
                        return { ...prev, [key]: e.target.value };
                      });
                    }}
                    custom_class="AddressForm__wrapper__form__input"
                  />
                </div>
              );
            }
            return null;
          })}


          <select required
            defaultValue={formData.country || "Lebanon"}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, country: e.target.value };
              });
            }}
            style={{ width: "100%", padding: "5px" }}>

            {COUNTRIES_TO_SHIP.map(item => (
              <option>{item.name}</option>
            ))}
          </select>


          <Button
            label={t("cartButton")}
            className="AddressForm__wrapper__form__btn"
          />
        </form>
      </div>
    </div>
  );
};

export default AddressForm;
