export const categories = [
  {
    id: 1,
    title: "RetailA",
    items: [
      {
        id: 100,
        name: "BodySuites",
        href: "/collection/Bodysuits",
      },
      // {
      //   id: 101,
      //   name: "Tshirts",
      //   href: "/collection/Tshirts",
      // },
      // {
      //   id: 102,
      //   name: "Caps",
      //   href: "/collection/Caps",
      // },
      {
        id: 103,
        name: "Hospital Sets",
        href: "/collection/Stater-sets",
      },
      {
        id: 104,
        name: "Socks",
        href: "/collection/Socks",
      },
      // {
      //   id: 105,
      //   name: "Shoes",
      //   href: "/collection/Shoes",
      // },
      {
        id: 106,
        name: "Blankets",
        href: "/collection/Blankets",
      },
      {
        id: 107,
        name: "Comfy sets",
        href: "/collection/Comfy-sets",
      },
      {
        id: 108,
        name: "Special Prices",
        href: "/collection/Special-Prices",
      },
      {
        id: 109,
        name: "Babysuits",
        href: "/collection/Babysuits",
      },
      // NEW
      {
        id: 101,
        name: "Tshirts",
        href: "/collection/Tshirts",
      },
      {
        id: 1012,
        name: "Knit wear",
        href: "/collection/knit-wear",
      },
      {
        id: 10122,
        name: "Trousers",
        href: "/collection/trousers",
      },
      {
        id: 1013,
        name: "Jackets",
        href: "/collection/jackets",
      },
      {
        id: 1014,
        name: "Accessories",
        href: "/collection/accessories",
      },
    ],
  },
  {
    id: 2,
    title: "Whole Sale",
    items: [
      {
        id: 200,
        name: "BodySuites",
        href: "/collection/wholesale-Bodysuits",
      },
      // {
      //   id: 201,
      //   name: "Tshirts",
      //   href: "/collection/wholesale-Tshirts",
      // },
      // {
      //   id: 202,
      //   name: "Caps",
      //   href: "/collection/wholesale-Caps",
      // },
      {
        id: 203,
        name: "Hospital Sets",
        href: "/collection/wholesale-stater-sets",
      },
      {
        id: 204,
        name: "Socks",
        href: "/collection/wholesale-Socks",
      },
      // {
      //   id: 205,
      //   name: "Shoes",
      //   href: "/collection/wholesale-Shoes",
      // },
      {
        id: 206,
        name: "Blankets",
        href: "/collection/wholesale-Blankets",
      },
      {
        id: 207,
        name: "Comfy sets",
        href: "/collection/wholesale-Comfy-sets",
      },
      {
        id: 109,
        name: "Babysuits",
        href: "/collection/wholesale-Babysuits",
      },
      {
        id: 208,
        name: "Special Prices",
        href: "/collection/wholesale-special-prices",
      },
      // NEW
      {
        id: 1010,
        name: "Tshirts",
        href: "/collection/wholesale-Tshirts",
      },
      {
        id: 10120,
        name: "Knit wear",
        href: "/collection/wholesale-knit-wear",
      },
      {
        id: 101220,
        name: "Trousers",
        href: "/collection/wholesale-trousers",
      },
      {
        id: 10130,
        name: "Jackets",
        href: "/collection/wholesale-jackets",
      },
      {
        id: 10140,
        name: "Accessories",
        href: "/collection/wholesale-accessories",
      },
    ],
  },
];
export const COLORS_FOR_FILTER = [
  { id: 1, color: "red" },
  { id: 2, color: "blue" },
  { id: 3, color: "green" },
  { id: 4, color: "yellow" },
  { id: 5, color: "black" },
  { id: 6, color: "white" },
  { id: 7, color: "orange" },
  { id: 8, color: "purple" },
  { id: 9, color: "pink" },
  { id: 10, color: "gray" },
];

export const CURRENCIES_DATA = [
  { id: 1, currency: "USD" },
  { id: 2, currency: "EUR" },
  { id: 3, currency: "JPY" },
  { id: 4, currency: "GBP" },
  { id: 5, currency: "AUD" },
  { id: 6, currency: "CAD" },
  { id: 7, currency: "CHF" },
  { id: 8, currency: "CNY" },
  { id: 9, currency: "SEK" },
  { id: 10, currency: "NZD" },
];

export const COUNTRIES = [
  {
    id: 1,
    name: "Lebanon",
    currency: "USD",
    language: "en",
    image: "/images/countries/lb.svg",
  }, {
    id: 2,
    name: "UK",
    currency: "GBP",
    language: "en",
    image: "/images/countries/lb.svg",
  },
  // {
  //   id: 2,
  //   name: "Brazil",
  //   currency: "BRL",
  //   language: "br",
  //   image: "/images/countries/br.svg",
  // },
  // {
  //   id: 3,
  //   name: "Abidjan",
  //   currency: "CFA",
  //   language: "ab",
  //   image: "/images/countries/ci.svg",
  // },
  // {
  //   id: 4,
  //   name: "Dubai",
  //   currency: "AED",
  //   language: "en",
  //   image: "/images/countries/ci.svg",
  // },
];
export const RATES = {
  usd: 1,
  gbp: 0.78,
  eur: 0.92
  // brl: 4.7378,
  // oxf: 588.89438,
  // aed: 0.27,
};


export const termsData = [
  {
    id: "",
    category: 'Terms and Conditions',
    items: [
      'Our Business is domiciled in Lebanon, and all transactions and interactions are governed by the laws of Lebanon.',
      'We accept payment via credit/debit',
      'We do not conduct business with individuals, companies, or countries subject to sanctions imposed by OFAC or other relevant authorities',
      'Minors under the age of 18 are prohibited from registering accounts or making purchases on our platform',
      "We recommend that cardholders retain records of their transactions for future reference and adhere to our company's policies regarding returns, refunds, and privacy",
      'Users are solely responsible for maintaining the confidentiality of their account credentials and are advised not to share their login information with anyone',
    ],
  },
  {
    id: "privacy",
    category: 'Privacy Policy',
    items: [
      'We assure you that credit/debit card details and personally identifiable information will not be stored, sold, shared, rented, or leased to third parties.',
      'We emphasize our dedication to data privacy and security measures. While we strive to maintain online disclosure security, it cannot be guaranteed.',
      'We clarify that the merchant is not accountable for the privacy policies of linked websites.',
      'Customers will be notified about periodic updates to website policies and terms & conditions.',
      'Notify customers about periodic updates to website policies and terms & conditions.',
    ],
  },
  {
    id: "shipping",
    category: 'Delivery/Shipping Policy (if applicable)',
    items: [
      'ALL CUSTOMS FEES, TAXES, DUTIES AND ANY OTHER ADDITIONAL COSTS ASSOCIATED WITH THE ORDER ONCE IT IS HANDED OVER TO THE SHIPPING CARRIER ARE THE RESPONSIBILITY OF THE CUSTOMER. PLEASE CONTACT YOUR LOCAL CUSTOMS OFFICE FOR MORE INFORMATION',
      'You will receive an email once your order has been dispatched which will contain tracking information. You can also keep an eye out on your delivery or contact our Customer Care Team (solbabywear@outlook.com) to help track your order.',
      'We ship worldwide. Orders will be shipped within 24-48 hours of payment and will take 3-5 business days for express delivery anywhere outside Lebanon. For express shipments, this does not include standard processing time of 2 business days. Business days do not include Saturdays, Sundays, US Holidays or Lebanon National Holidays.',
      'Please note that Solbabybrand is not responsible for any potential delay after item has been handed over to the shipping carrier as it is provided by the carrier and may vary with package origin and destination. The selection of shipping options are available to you upon checkout.',
      'Please note that local charges (sales tax, customs duty) may occur, depending on your region and local customs duties. These charges are at the customers own expense.',
      'Please note that SOLBABYBRAND also does not provide refunds to customers for failure to pay duties and taxes associated with their orders. Please contact us prior to placing your order if you have concerns regarding import taxes and customs duties.',
      `Lebanon:
      Same day delivery / 48 working hours – 3$
      United Arab Emirates 
      3-5 working days
      United Kingdom (UK):
      Standard shipping 4-6 days - 21$
      All other countries:
      Express shipping 3-5 Days - 25$`,
      "*All custom fees, taxes and any other additional costs associated with your purchase overseas are the responsibility of the customer. Please contact your local customs office for more information. Thank you for your understanding"
    ],
  },
  {
    id: "refund",
    category: 'Refund Policy',
    items: [
      "International: We offer a 7-day return policy for orders outside Lebanon. please contact us solbabywear@outlook.com",
      'Lebanon: We offer a 2-day return policy for orders in Lebanon. please contact us solbabywear@outlook.com',
      'WE DO NOT OFFER REFUNDS TO THE ORIGINAL METHOD OF PAYMENT. ONLY REFUNDS IN STORE CREDIT',
      'To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. ',
    ],
  },
  {
    id: "",
    category: 'Cancellation Policy',
    items: [
      'If you need to change or cancel your order, please contact us immediately. We process and ship orders quickly (we’re fast!). Once our warehouse has processed your order, we will be unable to make any changes..',
    ],
  },
  {
    id: "",
    category: 'ORDER LOST OR STOLEN',
    items: [
      'SolBabyBrand  is not liable for orders lost or stolen after confirmed delivery, emphasizing the end of our responsibility upon delivery confirmation. We strongly recommend purchasing Route Order Protection for added security, covering lost, stolen, or damaged orders, ensuring eligibility for a replacement or refund.',
    ],
  },


  {
    id: "",
    category: 'Contact Us',
    items: [
      'Sol Baby Brand, solbabywear@outlook.com, +961 70 568 556, Achrafieh, Lebanon.',
    ],
  },

];

export default termsData;

export const COUNTRIES_TO_SHIP = [

  { name: 'Lebanon', code: 'LB', type: "lb" },
  { name: 'Qatar', code: 'QA', type: "mea" },
  { name: 'KSA', code: 'SA', type: "mea" },
  { name: 'Jordan', code: 'JO', type: "mea" },
  { name: 'UAE', code: 'AE', type: "mea" },
  { name: 'Austria', code: 'AT', type: "eu" },
  { name: 'Belgium', code: 'BE', type: "eu" },
  { name: 'Bulgaria', code: 'BG', type: "eu" },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Croatia', code: 'HR', type: "eu" },
  { name: 'Cyprus', code: 'CY', type: "eu" },
  { name: 'Czech Republic', code: 'CZ', type: "eu" },
  { name: 'Denmark', code: 'DK', type: "eu" },
  { name: 'Estonia', code: 'EE', type: "eu" },
  { name: 'Finland', code: 'FI', type: "eu" },
  { name: 'France', code: 'FR', type: "eu" },
  { name: 'Germany', code: 'DE', type: "eu" },
  { name: 'Greece', code: 'GR', type: "eu" },
  { name: 'Hungary', code: 'HU', type: "eu" },
  { name: 'Ireland', code: 'IE', type: "eu" },
  { name: 'Italy', code: 'IT', type: "eu" },
  { name: 'Latvia', code: 'LV', type: "eu" },
  { name: 'Lithuania', code: 'LT', type: "eu" },
  { name: 'Luxembourg', code: 'LU', type: "eu" },
  { name: 'Malta', code: 'MT', type: "eu" },
  { name: 'Netherlands', code: 'NL', type: "eu" },
  { name: 'Poland', code: 'PL', type: "eu" },
  { name: 'Portugal', code: 'PT', type: "eu" },
  { name: 'Romania', code: 'RO', type: "eu" },
  { name: 'Slovakia', code: 'SK', type: "eu" },
  { name: 'Slovenia', code: 'SI', type: "eu" },
  { name: 'Spain', code: 'ES', type: "eu" },
  { name: 'Sweden', code: 'SE', type: "eu" },
];

export const handleDeliveryPrice = (total, country) => {

  const [getCountry] = COUNTRIES_TO_SHIP.filter(item => country == item.name)


  if (country.toLowerCase() == "lebanon") {
    return 3
  }




  if (country.toLowerCase() == "United Kingdom".toLowerCase()) {
    return 15
  }




  if (getCountry.type == "eu") {
    return 19

  }


  if (getCountry.type == "mea") {

    return 13

  }



}