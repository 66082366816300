import { useNavigate } from "react-router-dom";
import Button from "../Button/button";

const DrawJsx = ({ version = "", href = "", desc, title }) => {

  const navigate = useNavigate();

  return (
    <div className="drawJSX">
      <div className="drawJSX__body">
        <h1 style={version === "black" ? { color: "black" } : {}}>{title}</h1>
        <p style={version === "black" ? { color: "black" } : {}}>{desc}</p>
        <Button
          label="Shop Now"
          handleClick={() => navigate(href)}
          className={
            version === "black"
              ? "drawJSX__body__btn--black"
              : "drawJSX__body__btn"
          }
        />
      </div>
    </div>
  );
}
export default DrawJsx
