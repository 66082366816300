import React from "react";
import "./style.scss";

const CustomSkeleton = ({ width, height, times }) => {
  const style = {
    width: width,
    height: height,
  };

  if (!times) {
    return <div className="customSkeleton" style={style}></div>;
  } else {
    return (
      <div className="customSkeleton__wrapper">
        {Array(times)
          .fill(0)
          .map((skeleton) => (
            <div
              key={Math.random()}
              className="customSkeleton"
              style={style}
            ></div>
          ))}
      </div>
    );
  }
};

export default CustomSkeleton;
