import React from "react";
import "./style.scss";
const Input = (props) => {
  return (
    <input
      type="text"
      {...props}
      className={`textField ${props.custom_class ? props.custom_class : null}`}
    />
  );
};

export default Input;
