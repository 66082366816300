import React, { useContext, useEffect, useState } from "react";
import Attributes from "../Attributes/attributes";
import Button from "../Button/button";
import CustomSkeleton from "../CustomSkeleton/customSkeleton";
import ProductsGrid from "../ProductsGrid/productsGrid";
import CurrencySymbol from "../CurrencySymbol/currencySymbol";
import QuantityCounter from "../QuantityCounter/quantityCounter";
import "./style.scss";
import { calculateSalePercentage, checkIfPrice } from "../../Utils/other";
import { CartContext } from "../../Context/cartContext";
import { useTranslation } from "react-i18next";
import { RATES } from "../../data/data";

const ProductDetails = ({
  product,
  setIsCart,
  isLoading,
  color = "",
  setColor = () => { },
  size = "",
  setSize = () => { },
}) => {
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [startY, setStartY] = useState(0);
  const [quantity, SetQuantity] = useState(1);
  // const [color, setColor] = useState("");
  // const [size, setSize] = useState("");

  const { addItem, exchangeRate, currency } = useContext(CartContext);

  console.log(exchangeRate, currency);
  const handleCurrencySymbol = () => {
    return <CurrencySymbol currencyCode={currency} />;
  };


  const handleBtnLabel = () => {
    // product.stock ? t("addToCart") : t("outOfStock")
    if (product.stock && product.price) {
      return t("addToCart")
    }
    if (!product.stock && product.price) {
      return t("outOfStock")
    }
    else {
      return "Contact whatsapp"
    }

  }
  return (
    <div
      className={`productDetails  `}
    >
      <div className={`${isOpen ? "" : ""}`}>
        <div className="">
          <div
            style={isOpen ? { paddingTop: "5px" } : {}}
            className={`productDetails__wrapper `}
          >
            <p className="productDetails__wrapper__name">
              {!isLoading ? (
                product.name
              ) : (
                <CustomSkeleton height="15px" width="180px" />
              )}
            </p>

            <p
              className={`productDetails__wrapper__price ${product.sale ? "sale" : ""
                }`}
            >
              {!isLoading ?
                checkIfPrice(product, RATES[currency.toLowerCase()], currency)
                : (
                  <CustomSkeleton height="15px" width="80px" />
                )}
            </p>
            {product.sale ? (
              <p className="productDetails__wrapper__price__sale">
                {`
                
                 ${Number(product.sale * exchangeRate).toFixed()} `}
                {currency ? currency.toUpperCase() : currency}
                {/* {handleCurrencySymbol()} */}
              </p>
            ) : null}

            {product.name && product?.colors?.length ? (
              <Attributes
                setColor={setColor}
                data={product.colors}
                isColor={true}
                color={color}
              />
            ) : isLoading ? (
              <CustomSkeleton times={5} height="35px" width="35px" />
            ) : null}

            {product.name && product?.sizes?.length ? (
              <Attributes
                size={size}
                setSize={setSize}
                data={product.sizes}
                isColor={false}
              />
            ) : isLoading ? (
              <CustomSkeleton times={5} height="35px" width="35px" />
            ) : null}
            <div style={{ marginLeft: "10px" }} className="">
              {isLoading ? (
                <CustomSkeleton height="30px" width="90px" />
              ) : (
                <QuantityCounter
                  product={product}
                  quantity={quantity}
                  SetQuantity={SetQuantity}
                />
              )}
            </div>
            <div className="productDetails__wrapper__btn">
              {!isLoading ? (
                <Button
                  label={handleBtnLabel()}
                  className="productDetails__wrapper__btn--btn"
                  outOfStock={product.stock ? false : true}
                  handleClick={() => {
                    if (product?.stock && product.price) {
                      addItem({
                        quantity,
                        ...product,
                        attributes: {
                          color: color || "",
                          size: size || "",
                        },
                      });
                      setIsOpen(false);
                      setIsCart(true);
                    }
                    else {
                      const currentUrl = encodeURIComponent(window.location.href)
                      window.location.href = `https://api.whatsapp.com/send?phone=96170568556&text=Hello+intrested+in+${currentUrl}`
                    }
                  }}
                />
              ) : (
                <CustomSkeleton height="40px" width="133px" />
              )}
            </div>
            <div className="productDetails__wrapper__description">
              <p className="productDetails__wrapper__description__text">
                {!isLoading ? (
                  product.description
                ) : (
                  <CustomSkeleton height="90px" width={`calc(100% - 50px)`} />
                )}
              </p>
            </div>
            <div className="productDetails__wrapper__related">
              <p
                forbottomsheet="true"
                className="productDetails__wrapper__related__title"
              >
                {/* {!isLoading ? (
                  "You may also like"
                ) : (
                  <CustomSkeleton height="28px" width="135px" />
                )} */}
              </p>
              <div
                forbottomsheet="true"
                className="productDetails__wrapper__related__wrapper"
              >
                {product.category && product.category.slug.current ? (
                  <ProductsGrid
                    currentProductSlug={product.slug.current}
                    setIsOpen={setIsOpen}
                    collection={product.category.slug.current}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ProductDetails;
