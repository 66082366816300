import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { BsArrowLeft } from "react-icons/bs";
import OrderOverview from "../OrderOverview";
import RadioInput from "../RadioInput";
import Button from "../Button/button";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../Context/cartContext";
import {
  generateOrderNumber,
  getDate,
  handleCheckout,
  handleOverViewPrice, handleSendEmail, randomIdGenerator
} from "../../Utils/other";
import { useTranslation } from "react-i18next";
import { RATES } from "../../data/data";
import { submitOrderData } from "../../Utils/fetching";
const Payment = ({
  isOrderPlaced,
  setIsOrderPlaced,
  formData,
  setFormData,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const { clearCart, cart, currency, exchangeRate, isLoggedin } =
    useContext(CartContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setFormData((prev) => {
    //   return {
    //     ...prev,
    //     total:
    //   };
    // });

    const total = {
      total: handleOverViewPrice(cart, RATES[currency.toLowerCase()], formData.shipping.price),
      currency: currency,
    };
    const orderData = {
      orderID: randomIdGenerator(),
      email: formData.email,
      status: "proceeding",
      cost: `${total.total} ${currency}`,
      orderDate: getDate(),
    };
    switch (formData.payment) {
      case "Credit Card":
        try {

          // localStorage.setItem("formData", JSON.stringify(formData))
          // localStorage.setItem("cartTotal", JSON.stringify(total))


          const orderData = {
            orderID: generateOrderNumber(),
            formData: JSON.stringify(formData),
            status: 'unpaid',
            cart: JSON.stringify(cart),
            cost: Number(total.total).toFixed(2),
            currency: total.currency.toUpperCase(),
            orderDate: new Date().toISOString(),
          };
          if (!isLoading) {
            setIsLoading(true)
            submitOrderData(orderData).then(data => {
              handleCheckout(orderData);
            })

          }



        } catch (error) {
          console.error(error);
        }
        break;
      case "Cash on delivery":
        await handleSendEmail(formData, total, cart);
        if (isLoggedin) {
          // await submitOrderData(orderData);
        }
        navigate("/");
        setIsOrderPlaced(true);
        clearCart();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log(1, formData);

  }, [])
  return (
    <div className="payment">
      <form onSubmit={handleSubmit} className="payment__wrapper">
        <BsArrowLeft
          fontSize={19}
          className="payment__wrapper__icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <OrderOverview
          coupon={20}
          shippingCost={formData?.shipping?.price}
          className=""
          key={99}
        />
        <p className="payment__wrapper__title">Choose Payment Method</p>
        <div className="payment__wrapper__radio">
          {/*
          <RadioInput
            keyName="payment"
            description="Credit Card"
            name="payment"
            setFormData={setFormData}
            price=""
            id={Math.random()}
          />
          */}
          {formData.country.toLowerCase() == "lebanon" && (
            <RadioInput
              description="Cash on delivery"
              name="payment"
              setFormData={setFormData}
              keyName="payment"
              price=""
              id={Math.random()}
            />
          )}


          <RadioInput
            description="Credit Card"
            name="payment"
            setFormData={setFormData}
            keyName="payment"
            price=""
            id={Math.random()}
          />
        </div>
        <Button
          isDisabled={isLoading}
          handleClick={() => { }}
          label={t("cartButton")}
          className="shipping__wrapper__form__btn"
        />
      </form>
    </div>
  );
};

export default Payment;
