import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { fetchProductsByCategory } from "../../Utils/fetching";
import Card from "../Card/card";
import SkeletonCard from "../SkeletonCard/skeletonCard";

const ProductsGrid = ({
  collection,
  setIsOpen = () => { },
  currentProductSlug,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const fetchedProducts = await fetchProductsByCategory(collection, 4);
        setProducts(fetchedProducts.products);
        setIsLoading(false);
      } catch (e) {
      }
    };
    if (isLoading) {
      fetchProducts();
    }
    // eslint-disable-next-line
  }, [collection]);

  const scrollToTop = () => {
    const element1 = document.querySelector(".scrollable");
    const element2 = document.querySelector(".productDetails");
    if (element1) {
      element1.scrollTo(0, 0);
    } else {
      element2.scrollTo(0, 0);
    }
  };

  return (
    <div className="productsGrid">
      <div className="productsGrid__wrapper">
        {products?.length
          ? products?.map(
            (item) =>
              currentProductSlug !== item.slug.current && (
                <div
                  onClick={() => {
                    scrollToTop();

                    setIsOpen(false);
                  }}
                  key={item.slug.current}
                  className="productsGrid__wrapper__item"
                >
                  <Link to={`/product/${item.slug.current}`}>
                    <Card product={item} />
                  </Link>
                </div>
              )
          )
          : Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="productsGrid__wrapper__item">
              <SkeletonCard />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProductsGrid;
