import React, { useEffect, useState } from 'react';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const TestPage = () => {
    const [sessionHash, setSessionHash] = useState('');

    useEffect(() => {
        // getOrderByOrderID("SE9XZDPG").then(data => {
        // })





        console.log(
            getUnicodeFlagIcon('lb'));

    }, [])


    return (
        <div>
            {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(item => (
                <br />
            ))}

        </div>
    )
}

export default TestPage