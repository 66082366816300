import React, { useContext } from "react";
import "./style.scss";
import CurrencySymbol from "../CurrencySymbol/currencySymbol";
import { CartContext } from "../../Context/cartContext";
import { handleOverViewPrice, handlePrice } from "../../Utils/other";
import { RATES } from "../../data/data";

const OrderOverview = ({ className = "", shippingCost = 0, coupon = 0 }) => {
  const { cart, exchangeRate, currency } = useContext(CartContext);

  // const getTotalPrice = () => {
  //   let total = 0;
  //   cart.forEach((item) => {
  //     total += item.price * item.quantity;
  //   });
  //   return (total * exchangeRate).toFixed();
  // };

  const handleSymbol = () => {
    return <CurrencySymbol currencyCode={currency} />;
  };

  return (
    <div className={`orderOverview ${className}`}>
      <div className="orderOverview__wrapper">
        <h4 className="orderOverview__wrapper__title">Your Order</h4>
        <div className="orderOverview__wrapper__subtotalSect">
          <p className="orderOverview__wrapper__subtotalSect__product">
            Products
          </p>
          <p className="orderOverview__wrapper__subtotalSect__subtotal">
            {/* Prices */}
          </p>
        </div>
        <div className="orderOverview__wrapper__products">
          {cart.map((item) => (
            <div
              key={item.name}
              className="orderOverview__wrapper__products__item"
            >
              <p className="orderOverview__wrapper__products__item__name">
                {item.name} &times; {item.quantity}
              </p>
              <p className="orderOverview__wrapper__products__item__price">
                {item.sale
                  ? (item.sale * RATES[currency.toLowerCase()]).toFixed()
                  : (item.price * RATES[currency.toLowerCase()]).toFixed()}
                {handleSymbol()}
              </p>
            </div>
          ))}
        </div>
        <div className="orderOverview__wrapper__footer">
          <div className="orderOverview__wrapper__footer__subtotal">
            <p className="orderOverview__wrapper__footer__subtotal__label">
              Subtotal
            </p>
            <p className="orderOverview__wrapper__footer__subtotal__price">
              {Number(handlePrice(cart) * RATES[currency.toLowerCase()])
                .toFixed()
                .toLocaleString("en", {
                  useGrouping: true,
                })}
              {handleSymbol()}
            </p>
          </div>
          <div className="orderOverview__wrapper__footer__shipping">
            <p className="orderOverview__wrapper__footer__shipping__label">
              Shipping
            </p>
            <p className="orderOverview__wrapper__footer__shipping__price">
              {Number(shippingCost)} {handleSymbol()}
            </p>
          </div>
          <div className="orderOverview__wrapper__footer__total">
            <p className="orderOverview__wrapper__footer__total__label">
              Total
            </p>
            <p className="orderOverview__wrapper__footer__total__price">
              {handleOverViewPrice(cart, RATES[currency.toLowerCase()], shippingCost)}
              {handleSymbol()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOverview;
