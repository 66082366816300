import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const StackedCard = ({ img, Comp, href = "" }) => {
  return (
    <div className="home__wrapper__card">
      {img ? (
        <Link to={href}>
          <img className="home__wrapper__card__img" src={img} alt="" />
        </Link>
      ) : null}
      {Comp ? Comp : null}
    </div>
  );
};

export default StackedCard;
