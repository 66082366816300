import React, { useContext, useDebugValue, useEffect, useState } from "react";
import Input from "../../Components/Input/input";
import "./style.scss";
import Button from "../../Components/Button/button";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { submitUserData } from "../../Utils/fetching";
import Loader from "../../Components/Loader/loader";
import Cookies from "js-cookie";
import { CartContext } from "../../Context/cartContext";
const SignUp = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  const { setIsLoggedin, isLoggedin } = useContext(CartContext);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    country: "",
    number: "",
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    submitUserData(userData)
      .then((response) => {
        setIsLoading(false);
        Cookies.set("userData", JSON.stringify(userData), { expires: 7 });
        setIsLoggedin(true);
        navigate("/account");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error submitting user data:", error);
      });
  };
  useEffect(() => {
    if (isLoggedin) {
      navigate("/account");
    }
  }, []);
  return (
    <div className="signUp">
      <form onSubmit={handleSubmit}>
        <div className="signUp__wrapper">
          <h1 className="signUp__wrapper__heading">SignUp</h1>
          {Object.keys(userData).map((key, index) => (
            <Input
              id={key}
              name={key}
              type="text"
              required
              autoComplete="on"
              key={index}
              placeholder={key} // {t("placeholders", { returnObjects: true })[key]}
              custom_class="signUp__wrapper__input"
              onChange={(e) => {
                setUserData((prev) => {
                  return { ...prev, [key]: e.target.value };
                });
              }}
            />
          ))}
          <Button
            isDisabled={isLoading}
            label={!isLoading ? t("cartButton") : <Loader />}
            className="AddressForm__wrapper__form__btn"
          />
          <Link to="/login">
            <p className="signUp__wrapper__signUp">Already have an account?</p>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
