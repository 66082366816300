import React from "react";
import "./style.scss";

const Button = ({
  className,
  label,
  size = "lg",
  handleClick = () => {},
  outOfStock = false,
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      onClick={handleClick}
      className={`button ${className} ${
        outOfStock ? "button--outOfStock" : ""
      } ${size ? `button--${size}` : ""}
      ${isDisabled ? "button--disabled" : ""}
      `}
    >
      {label}
    </button>
  );
};

export default Button;
