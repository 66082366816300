import React from "react";
import { urlFor } from "../../config/config";
import "./style.scss";
const SearchItem = ({ product }) => {
  return (
    <div className="searchItem">
      <div className="searchItem__wrapper">
        <div className="searchItem__wrapper__left">
          <img
            src={urlFor(product.mainImage)}
            alt=""
            loading="lazy"
            className="searchItem__wrapper__left__img"
          />
        </div>
        <div className="searchItem__wrapper__right">
          <p className="searchItem__wrapper__right__name">{product.name}</p>
          <p className="searchItem__wrapper__right__price">{product.price}$</p>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
