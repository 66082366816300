import React, { useContext } from "react";
import QuantityCounter from "../QuantityCounter/quantityCounter";
import "./style.scss";
import { urlFor } from "../../config/config";
import { CartContext } from "../../Context/cartContext";
import { checkIfPrice, checkIfPriceOnlyNumberQuantity, checkIfPriceOnlyNumberQuantitySale } from "../../Utils/other";
import { RATES } from "../../data/data";

const CartCard = ({ product }) => {
  const { removeItem, exchangeRate, currency } = useContext(CartContext);

  const handlePrice = () => {
    if (!product.sale) {
      const prc = checkIfPriceOnlyNumberQuantity(product, RATES[currency.toLowerCase()], currency, product.quantity)
      return prc * product.quantity;
    } else {
      let x = checkIfPriceOnlyNumberQuantitySale(product, RATES[currency.toLowerCase()], currency, product.quantity)
      return x * product.quantity;
    }
  };

  return (
    <div className="cartCard">
      <div className="cartCard__wrapper">
        <div className="cartCard__wrapper__left">
          <img
            src={urlFor(product.mainImage)}
            alt=""
            loading="lazy"
            className="cartCard__wrapper__left__img"
          />
        </div>
        <div className="cartCard__wrapper__right">
          <div className="cartCard__wrapper__right__top">
            <p className="cartCard__wrapper__right__top__name">
              {product.name}
            </p>
            <p className="cartCard__wrapper__right__top__price">
              {handlePrice()}
              {` ${currency}`}
            </p>
            <p className="cartCard__wrapper__right__top__attr">
              {product?.attributes
                ? `${product?.attributes?.size || ""} ${product?.attributes?.size && product?.attributes?.color
                  ? "|"
                  : ""
                } ${product?.attributes?.color || ""}`
                : ""}
            </p>
            {/* <p className="cartCard__wrapper__right__top__size">{product.chosenSize}</p>
          <p className="cartCard__wrapper__right__top__color">
            {product.chosenColor}
          </p> */}
          </div>
          <QuantityCounter
            inCart
            product={product}
            productId={product._id}
            quantity={product.quantity}
          />
          <p
            className="cartCard__wrapper__right__delete"
            onClick={() => {
              removeItem(product);
            }}
          >
            DELETE
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
