import React, { useState, useCallback, useEffect } from "react";
import ProductDetails from "../../Components/ProductDetails/productDetails";
import ImageViewer from "react-simple-image-viewer";
import { useParams } from "react-router-dom";
import { fetchProductBySlug } from "../../Utils/fetching";
import { urlFor } from "../../config/config";
import SkeletonCard from "../../Components/SkeletonCard/skeletonCard";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';

import "swiper/css";
import "swiper/css/pagination";
import "./style.scss";

const Product = ({ setIsCart }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { slug } = useParams();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");

  const [fetchedProduct, setFetchedProduct] = useState({});

  const openImageViewer = useCallback((index) => {
    setActiveImageIndex(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  useEffect(() => {
    setColor("");
    setSize("");
  }, [slug]);

  useEffect(() => {
    const fetchProduct = async () => {
      window.scrollTo(0, 0);
      try {
        setIsLoading(true);
        const product = await fetchProductBySlug(slug);

        const { mainImage, gallery } = product;
        const images = [];

        if (mainImage && mainImage.asset) {
          const mainImageUrl = urlFor(mainImage);
          images.push(mainImageUrl);
        }

        if (gallery && gallery?.length > 0) {
          for (const image of gallery) {
            if (image.asset) {
              const imageUrl = urlFor(image);
              images.push(imageUrl);
            }
          }
        }

        setFetchedProduct(product);
        setProductImages(images);
        setIsLoading(false);
        if (product?.sizes?.length) {
          setSize(product?.sizes[0]);
        }
        if (product?.colors?.length) {
          setColor(product?.colors[0]);
        }
      } catch (error) {
        // navigate("/");
      }
    };

    fetchProduct();
  }, [slug]);

  return (
    <div className="product">

      <div className="product__wrapper">
        {isLoading ? (
          <div className="product__wrapper__skeleton">
            <SkeletonCard className="product__wrapper__skeleton__item" />
          </div>
        ) : (
          <Swiper
            spaceBetween={10}
            autoHeight={true}
            pagination={true}
            slidesPerView={1}
            modules={[Pagination]}

          >

            {productImages.map((img, index) => (

              <SwiperSlide>
                <img
                  key={index}
                  src={img}
                  loading="lazy"
                  alt=""
                  className="product__wrapper__img"
                  onClick={() => openImageViewer(index)}
                />
              </SwiperSlide>
            ))}


          </Swiper>

        )}

        <ProductDetails
          color={color}
          setColor={setColor}
          size={size}
          setSize={setSize}
          isLoading={isLoading}
          setIsCart={setIsCart}
          product={fetchedProduct}
        />

      </div>
      {isViewerOpen && productImages.length > 0 && (
        <ImageViewer
          closeOnClickOutside
          src={[productImages[activeImageIndex]]}
          currentIndex={0}
          onClose={closeImageViewer}
          disableZoom={true}
        />
      )}
    </div>
  );
};

export default Product;
