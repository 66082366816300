import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "bes4j075",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: true,
  token: "sks0mCbozm2FG7nlqrLFF5WxFb2B19dIZUCxG0PeVOQOkfksWxVvNfQgIasURw9UyYyGT2O7C0vvQaFXBiGod3rX3R5pJ5TRfsRbOZJfeskYYbcciJQjEYBjlnuRkQPD51H0KLNLY5QdQPoMSTwiZ0tXwRcwpXaSKmVwYkmMu4k4mUwpG2Fo",
});
const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source).url();
