import React, { useContext, useEffect } from "react";
import "./style.scss";
import { BsArrowLeft } from "react-icons/bs";
import RadioInput from "../../Components/RadioInput/index";
import DetailsTable from "../../Components/DetailsTable/detailsTable";
import { useNavigate } from "react-router-dom";
import Button from "../Button/button";
import { useTranslation } from "react-i18next";
import { RATES, handleDeliveryPrice } from "../../data/data";
import { handlePrice } from "../../Utils/other";
import { CartContext } from "../../Context/cartContext";

const Shipping = ({ formData, setFormData }) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/payment");
  };

  const { clearCart, cart, currency, exchangeRate, isLoggedin } = useContext(CartContext);

  return (
    <div className="shipping">
      <form onSubmit={handleSubmit} className="shipping__wrapper">
        <BsArrowLeft
          fontSize={19}
          className="shipping__wrapper__icon"
          onClick={() => {
            // navigate(-1, { state: { formData } });
            navigate(-1);
          }}
        />
        <DetailsTable formData={formData} />
        <div className="shipping__wrapper__radio">
          <RadioInput
            description="Express Shipping"
            name="shipping"
            setFormData={setFormData}
            price={handleDeliveryPrice(

              Number(handlePrice(cart) * RATES[currency.toLowerCase()])
                .toFixed()
              , formData.country)}
            keyName="shipping"
            id={1}
          />
        </div>
        <Button
          handleClick={() => { }}
          label={t("cartButton")}
          className="shipping__wrapper__form__btn"
        />
      </form>
    </div>
  );
};

export default Shipping;
