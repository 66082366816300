import { useContext, useEffect } from 'react';
import { CartContext } from "../../Context/cartContext";
import { useNavigate } from "react-router-dom";

const SuccessPay = ({ setIsOrderPlaced }) => {
    const { cart, clearCart } = useContext(CartContext);
    const navigate = useNavigate()
    useEffect(() => {
        const handleSuccess = async () => {
            // const formData = localStorage.getItem("formData")
            // const cartTotal = localStorage.getItem("cartTotal")
            // if (formData && cartTotal) {


            // await handleSendEmail(JSON.parse(formData), JSON.parse(cartTotal), cart);
            // localStorage.removeItem("formData")
            // localStorage.removeItem("cartTotal")

            setIsOrderPlaced(true);
            clearCart();
            navigate("/");



            // }
        }
        handleSuccess()
    }, [])
    return null
}

export default SuccessPay