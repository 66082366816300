import React from "react";
import { TfiClose } from "react-icons/tfi";
import Accordion from "../Accordition/accordition";
import PriceRange from "../PriceRange/priceRange";
import "./style.scss";
import Button from "../Button/button";
const Filter = ({
  isFilterOpened,
  setIsFilterOpened,
  filterType,
  setFilterType,
}) => {
  return (
    <div className="filter">
      <div
        className={`filter__wrapper ${
          isFilterOpened ? "filter__wrapper--show" : ""
        }`}
      >
        <TfiClose
          onClick={() => {
            setIsFilterOpened(false);
          }}
          className="filter__wrapper__icon"
        />
        {/* <Accordion
          title="Size"
          Comp={
            <SizeFilter filterType={filterType} setFilterType={setFilterType} />
          }
        /> */}
        <Accordion
          title="Price"
          Comp={
            <PriceRange filterType={filterType} setFilterType={setFilterType} />
          }
        />
        {/* <Accordion
          title="Color"
          Comp={
            <Colors filterType={filterType} setFilterType={setFilterType} />
          }
        /> */}
        <div className="filter__wrapper__bottom">
          <Button
            label="View results"
            size="sm"
            handleClick={() => {
              setIsFilterOpened(false);
            }}
          />
          <p className="filter__wrapper__bottom__clear">CLEAR</p>
        </div>
      </div>
    </div>
  );
};

export default Filter;
