import React, { createContext, useEffect, useReducer, useState } from "react";
import Cookies from "js-cookie";

// Define the initial state of the cart
const initialState = {
  items: [],
  isCartOpened: false,
};

// Create the context
export const CartContext = createContext(initialState);

// Define the reducer function to modify the state
const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const product = action.payload;
      const existingItem = state.items.find(
        (item) =>
          item._id === product._id &&
          JSON.stringify(action.payload.attributes) ===
          JSON.stringify(item.attributes)
      );

      if (existingItem) {
        const updatedItems = state.items.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );

        return { ...state, items: updatedItems };
      } else {
        return { ...state, items: [...state.items, action.payload] };
      }
    case "REMOVE_ITEM":
      const { attributes } = action.payload;
      return {
        ...state,
        items: state.items.filter((item) => {
          const hasSameId = item._id === action.payload._id;
          const hasSameAttributes =
            JSON.stringify(item.attributes) === JSON.stringify(attributes);
          return !(hasSameId && hasSameAttributes);
        }),
      };

    case "HANDLE_QUANTITY":
      const { product: updatedProduct, operation } = action.payload;
      const { _id: updatedProductId, attributes: updatedAttributes } =
        updatedProduct;

      const updatedItems = state.items.map((item) => {
        if (
          item._id === updatedProductId &&
          JSON.stringify(item.attributes) === JSON.stringify(updatedAttributes)
        ) {
          return {
            ...item,
            quantity: operation === "+" ? item.quantity + 1 : item.quantity - 1,
          };
        }
        return item;
      });

      return {
        ...state,
        items: updatedItems.filter((item) => item.quantity > 0),
      };

    case "SET_CART":
      return {
        ...state,
        items: action.payload,
      };

    case "CLEAR_CART":
      return {
        ...state,
        items: [],
      };
    case "TOGGLE_CART":
      return {
        ...state,
        isCartOpened: action.payload,
      };
    default:
      return state;
  }
};

// Create the CartProvider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart && storedCart.length > 2) {
      dispatch({ type: "SET_CART", payload: JSON.parse(storedCart) });
    }
  }, []);

  // Save cart data to localStorage whenever cart state changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state.items));
  }, [state.items]);

  // Define actions to modify the state
  const addItem = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };
  const toggleCart = (boolean) => {
    dispatch({ type: "TOGGLE_CART", payload: boolean });
  };

  const removeItem = (item) => {
    dispatch({ type: "REMOVE_ITEM", payload: item });
  };
  const handleQuantity = (product, operation) => {
    dispatch({
      type: "HANDLE_QUANTITY",
      payload: { product: product, operation: operation },
    });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
    localStorage.removeItem("cart");
  };
  const [currency, setCurrency] = useState(
    localStorage.getItem("isLanguagePopup") || "usd"
  );
  const [exchangeRate, setExchangeRate] = useState(1);
  const [isLoggedin, setIsLoggedin] = useState(() => {
    const userData = Cookies.get("userData");
    return userData ? true : false;
  });

  // Provide the state and actions to the children components
  return (
    <CartContext.Provider
      value={{
        cart: state.items,
        isCartOpened: state.isCartOpened,
        toggleCart,
        addItem,
        setIsLoggedin,
        removeItem,
        clearCart,
        handleQuantity,
        currency,
        setCurrency,
        isLoggedin,
        exchangeRate,
        setExchangeRate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
