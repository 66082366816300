import React from "react";
import "./style.css"

const HeroSectionSkeleton = () => {
    return (
        <div className="hero-section-skeleton">
            <div className="hero-section-skeleton__image"></div>
            <div className="hero-section-skeleton__content"></div>

        </div>
    );
};

export default HeroSectionSkeleton;
