import React, { useEffect } from 'react';
import './style.scss'; // Import your external CSS file for additional styling
import { scroller } from 'react-scroll';
import termsData from '../../data/data';
const TermsPage = () => {

    useEffect(() => {
        const hash = window?.location?.hash?.substring(1);

        // Check if hash exists and window object is defined
        if (hash && window) {
            scroller.scrollTo(hash, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
            });
        }
    }, [window?.location?.hash]);




    return (
        <div className="terms-container">

            {termsData.map(item => (
                <>
                    <section id={item.id} className="terms-section">
                        <h2>{item.category}</h2>
                        <ul>
                            {item.items.map(child => (
                                <li>{`- ${child}`}</li>
                            ))}
                        </ul>
                    </section>

                    <hr />
                </>
            ))}



            {/* Include sections for Payment Page Requirements if applicable */}
        </div>
    );
};

export default TermsPage;
