import React from "react";
import "./style.scss";
const SkeletonCard = ({ className }) => {
  return (
    <div
      forbottomsheet="true"
      className={`skeletonCard ${className ? className : ""}`}
    >
      <div forbottomsheet="true" className="skeletonCard__wrapper">
        <div className="skeletonCard__wrapper__media">
          <div
            forbottomsheet="true"
            className="skeletonCard__wrapper__media__image"
          />
        </div>
        <div forbottomsheet="true" className="skeletonCard__wrapper__lower">
          <p
            forbottomsheet="true"
            className="skeletonCard__wrapper__lower__name"
          />
          <p
            forbottomsheet="true"
            className="skeletonCard__wrapper__lower__price"
          />
          {/* <p className="skeletonCard__wrapper__lower__sale"></p> */}
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
