import React, { useState } from "react";
import "./style.scss";
const Accordion = ({ title, Comp }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="accordion">
      <div
        className="accordion__title"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {title}
        <div className={`accordion__title__icon ${isOpen ? "active" : ""}`}>
          <span className="accordion__title__icon__first">|</span>
          <span className="accordion__title__icon__second">|</span>
        </div>
      </div>
      <div className={`accordion__wrapper ${isOpen ? "active" : ""}`}>
        <div className="accordion__wrapper__collapsible">{Comp}</div>
      </div>
    </div>
  );
};

export default Accordion;
