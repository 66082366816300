import React, { useContext, useEffect, useState } from "react";
import Input from "../../Components/Input/input";
import "./style.scss";
import Button from "../../Components/Button/button";
import Loader from "../../Components/Loader/loader";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../Context/cartContext";

import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { checkUserCredentials, fetchUserInfo } from "../../Utils/fetching";

const Login = () => {
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { setIsLoggedin, isLoggedin } = useContext(CartContext);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { email, password } = userData;
    checkUserCredentials(email, password)
      .then((response) => {
        if (response) {
          setIsError(false);
          setIsSuccess(true);
          fetchUserInfo(email).then((res) => {
            Cookies.set("userData", JSON.stringify(res), { expires: 99 });
            setTimeout(() => {
              setIsLoading(false);
              setIsLoggedin(true);
              navigate("/account");
            }, 1500);
          });
        } else {
          setIsError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error submitting user data:", error);
      });
  };

  useEffect(() => {
    if (isLoggedin) {
      navigate("/account");
    }
  }, []);
  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <div className="login__wrapper">
          <h1 className="login__wrapper__heading">Login</h1>
          {Object.keys(userData).map((key, index) => (
            <Input
              id={key}
              name={key}
              type="text"
              required
              autoComplete="on"
              key={index}
              placeholder={key}
              custom_class="login__wrapper__input"
              onChange={(e) => {
                setUserData((prev) => {
                  return { ...prev, [key]: e.target.value };
                });
              }}
            />
          ))}
          {isError ? (
            <p className="login__wrapper__error">Invalid email or password</p>
          ) : null}
          {isSuccess ? (
            <p className="login__wrapper__success">Login Successfully</p>
          ) : null}
          <Button
            isDisabled={isLoading}
            label={!isLoading ? t("cartButton") : <Loader />}
            className="AddressForm__wrapper__form__btn"
          />
          <Link to="/sign-up">
            <p className="login__wrapper__signUp">Doesn't have an account?</p>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
