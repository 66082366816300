import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  const socials = [
    "TIKTOK",
    "INSTAGRAM",
    "FACEBOOK",
    // "TWITTER",
    // "PINTEREST",
    // "YOUTUBE",
  ];

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <p className="footer__wrapper__title">join our newsletter</p>
        <div className="footer__wrapper__socials">
          {socials.map((i) => (
            <div key={i} className="footer__wrapper__socials__item">
              {i}
            </div>
          ))}
        </div>
        <div className="footer__wrapper__bottom">
          <div className="footer__wrapper__bottom__item separator">
            <Link to="/terms#privacy">Privacy Policy</Link>
          </div>
          <div className="footer__wrapper__bottom__item separator">
            <Link to="/terms#refund">Refund Policy</Link>
          </div>
          <div className="footer__wrapper__bottom__item">
            <Link to="/terms#shipping">Shipping Policy</Link>
          </div>
        </div>
        <div className="footer__wrapper__bottom__item">
          Developed By{" "}
          <Link
            to=""
            className="footer__wrapper__bottom__link"
          >
            Syntaxify
          </Link>
        </div>
        <div className="footer__wrapper__bottom__item">
          <img src="https://vennei.com/wp-content/uploads/2023/03/i_payment-1-300x92.png"
            style={{
              objectFit: "cover",
              width: "100px"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
