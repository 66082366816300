import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
const DetailsTable = ({ formData }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="payment__wrapper__details">
      <div className="payment__wrapper__details__item">
        <div className="payment__wrapper__details__item__title">Contact</div>
        <div className="payment__wrapper__details__item__label">
          {formData.email}
        </div>
      </div>
      <div className="payment__wrapper__details__item">
        <div className="payment__wrapper__details__item__title">Ship to</div>
        <div className="payment__wrapper__details__item__label">
          {`${formData.country || "Lebanon"}, ${formData.city}, ${
            formData.address
          }`}
        </div>
      </div>
      <div className="payment__wrapper__details__item">
        <div className="payment__wrapper__details__item__title">Number</div>
        <div className="payment__wrapper__details__item__label">
          {i18n.language === "en"
            ? "+961 "
            : i18n.language === "br"
            ? "+55 "
            : i18n.language === "ab"
            ? "+225 "
            : null}
          {formData.number}
        </div>
      </div>
    </div>
  );
};

export default DetailsTable;
