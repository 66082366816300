import React from "react";
import currencySymbolMap from "currency-symbol-map";

const CurrencySymbol = ({ currencyCode }) => {
  const symbol = currencySymbolMap(currencyCode);

  return <span>{` ${symbol}`}</span>;
};

export default CurrencySymbol;
