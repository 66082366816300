import React, { createContext, useContext, useEffect, useState } from "react";
import "./general.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/home";
import Header from "./Components/Header/header";
import { CartContext, CartProvider } from "./Context/cartContext";

import Search from "./Components/Search/search";
import SideBar from "./Components/SideBar/sidebar";
import Cart from "./Components/Cart/cart";
import Collection from "./Pages/Collection/collection";
import Product from "./Pages/Product/product";
import Filter from "./Components/Filter/filter";
import Footer from "./Components/Footer/footer";
import AddressForm from "./Components/AddressForm/addressForm";
import Shipping from "./Components/Shipping/shipping";
import Payment from "./Components/Payment/payment";
import OrderPlaced from "./Components/OrderPlaced/orderPlaced";
import Login from "./Pages/Login/login";
import SignUp from "./Pages/Signup/signup";
import Account from "./Pages/Account/account";
import TemrsPage from "./Pages/Terms";
import TestPage from "./Pages/Test";
import SuccessPay from "./Pages/SuccessPay";

export const MyContext = createContext();
const App = () => {
  const { setCurrency, currency } = useContext(CartContext);

  const [isSearch, setIsSearch] = useState(false);
  const [userInfo, setUserInfo] = useState({}); // to change it globally
  const [isSideBar, setIsSiderBar] = useState(false);
  const [isCart, setIsCart] = useState(false);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false); //to False
  const [isChooseLanguageOpen, setIsChooseLanguageOpen] = useState(false);
  const [filterType, setFilterType] = useState({ type: "", data: "" });
  const pages = ["collection", "product", "login", "sign-up", "account", "terms"];
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    address: "",
    number: "",
    city: "",
    country: "Lebanon",
    ratio: "",
  });
  const hideHeaderPages = ["billing", "shipping", "payment"];
  const route = useLocation();
  useEffect(() => {
    if (isOrderPlaced) {
      setTimeout(() => {
        setIsOrderPlaced(false);
      }, 4000);
    }
  }, [isOrderPlaced, setIsOrderPlaced]);
  useEffect(() => {
    // const hasLanguagePopupUsed = localStorage.getItem("isLanguagePopup");
    // if (!hasLanguagePopupUsed) {
    //   // setIsChooseLanguageOpen(true);
    // } else {
    //   const data = JSON.parse(hasLanguagePopupUsed);
    //   // setCurrency(data.currency);
    //   changeLanguage(data.language);
    // }
  }, []);



  useEffect(() => {
    const isClear = localStorage.getItem("clear2");
    if (!isClear) {
      localStorage.clear();
      localStorage.removeItem("cart");
      localStorage.setItem("clear2", "true")
    }
  }, []);



  return (
    <CartProvider>
      <Search isSearch={isSearch} setIsSearch={setIsSearch} />
      <Cart isCart={isCart} setIsCart={setIsCart} />
      <Filter
        filterType={filterType}
        setFilterType={setFilterType}
        isFilterOpened={isFilterOpened}
        setIsFilterOpened={setIsFilterOpened}
      />
      {hideHeaderPages.every((i) => !route.pathname.includes(i)) ? (
        <Header
          pages={pages}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          isSideBar={isSideBar}
          setIsSiderBar={setIsSiderBar}
          isCart={isCart}
          setIsCart={setIsCart}
          isFilterOpened={isFilterOpened}
          setIsFilterOpened={setIsFilterOpened}
        />
      ) : null}
      {isOrderPlaced ? <OrderPlaced /> : null}

      {/* {isChooseLanguageOpen ? (
        <ChooseLanguage
          isChooseLanguageOpen={isChooseLanguageOpen}
          setIsChooseLanguageOpen={setIsChooseLanguageOpen}
        />
      ) : null} */}

      <SideBar isSideBar={isSideBar} setIsSiderBar={setIsSiderBar} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/success-pay" element={<SuccessPay setIsOrderPlaced={setIsOrderPlaced} />} />
        <Route
          path="/collection/:category"
          element={
            <Collection
              filterType={filterType}
              setFilterType={setFilterType}
              setIsFilterOpened={setIsFilterOpened}
            />
          }
        />
        <Route
          path="/billing"
          element={
            <AddressForm formData={formData} setFormData={setFormData} />
          }
        />
        <Route
          path="/shipping"
          element={<Shipping formData={formData} setFormData={setFormData} />}
        />
        <Route
          path="/payment"
          element={
            <Payment
              formData={formData}
              setFormData={setFormData}
              isOrderPlaced={isOrderPlaced}
              setIsOrderPlaced={setIsOrderPlaced}
            />
          }
        />
        <Route
          path="/product/:slug"
          element={<Product setIsCart={setIsCart} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/terms" element={<TemrsPage />} />
        <Route path="/account" element={<Account />} />
        <Route path="*" element={<>404</>} />
      </Routes>
      {(!route.pathname === "/" || !route.pathname.includes("product")) && (
        <Footer />
      )}
    </CartProvider>
  );
};

export default App;
